@import 'abstracts';

@keyframes countdownBar {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

body .p-toast.cap-toast {
  &.idle-toast {
    .p-toast-message.p-toast-message-warn {
      border-left-color: $white;

      .toast-icon {
        color: var(--cap-primary-color);
      }
    }

    .toast-summary {
      padding-bottom: 0.5rem;
      color: var(--cap-primary-color);
    }

    .toast-button {
      padding-top: 1rem;

      button {
        font-size: 1rem;
      }
    }

    .toast-progressbar {
      $gutter: 3px;
      margin-left: -$gutter;
      width: calc(100% + #{$gutter});
      height: 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom-left-radius: 4px;
      background-color: var(--cap-primary-color);
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      animation-name: countdownBar;

      @include media-breakpoint-between(xs, xl) {
        margin-top: 6px;
        position: relative;
        width: 100%;
        border-bottom-left-radius: 3px;
      }
    }
  }
}

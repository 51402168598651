@import 'abstracts';

cap-button p-button {
  &.p-disabled {
    background: none;
    opacity: 1;
  }

  button {
    display: flex;
    justify-content: center;
    border-radius: 30px;
  }

  .cap-button {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: $white;
  }
}

.popup-button {
  border: none;
  height: 60px;

  span {
    font-size: 1.25rem;
  }
}

.cancel-button {
  background: $blue;
  width: 135px;
}

.save-button {
  background: $success;
  width: 142px;
}

cap-dynamic-filters .cap-filters {
  justify-content: space-between;

  .filter-button {
    .cap-button.tertiary:not(:disabled) {
      background-color: $white;
      border: 1px solid var(--cap-primary-color);
    }

    .refresh-filter-button {
      margin-right: 10px;
    }

    cap-button.refresh-filter-button > p-button > button,
    &:enabled:hover {
      background-color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
      color: $white !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

@import 'abstracts';

cpb-list-renderer-filters {
  .table-filters.cap-table-filter {
    background-color: $body-background;
    align-items: flex-start;
    padding-top: 23px;

    .actions {
      display: flex;
    }

    div.checkbox-filter {
      border: 1px solid $border-color;

      .cap-checkbox-filter-label {
        color: $dark-gray;
        font-size: 0.88rem;
        font-weight: normal;
      }
    }
  }
}

.builder-detail-template__header-actions {
  display: flex;

  cap-button {
    margin-right: 5px;
  }
}

cpb-list-renderer {
  &:nth-child(1n) {
    .header,
    .cpb-filter-height-2 .p-datatable-thead cap-info-table-header > tr > th,
    .cpb-filter-height-3 .p-datatable-thead cap-info-table-header > tr > th {
      background: transparent;
    }
  }

  &.white {
    cpb-list-renderer-filters .cap-table-filter {
      background-color: $white;
    }
  }

  .actions cap-button p-button button:not(:disabled) {
    border-radius: 17px;
    height: 36px;
    font-size: 0.88rem;
    text-decoration: none;
    font-weight: bold;
  }

  &.info-table--outline {
    cap-info-table {
      .cap-info-table.p-datatable .p-datatable-tbody td.cap-info-table__actions-column--single {
        position: absolute;
        right: 0;
      }

      @include media-breakpoint-down(md) {
        .cap-info-table.p-datatable .p-datatable-tbody td .cap-info-table__actions-column--flexed {
          border-bottom: none;
        }
      }

      .cap-info-table.p-datatable {
        .p-datatable-thead {
          tr {
            th {
              font-weight: 600;
              font-size: 1.13rem;
              color: $table-header-color;

              &.th_d-none {
                display: none;
              }

              &.th_display-full-header div,
              &.display-full-header div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: normal;
                text-overflow: inherit;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {
            td {
              color: $body-color;
              background: $white;
              height: 76px;
              font-size: 1.13rem;

              &.display-full-text span {
                white-space: normal;
                text-overflow: inherit;
              }

              &.d-none {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.gray-lines {
    cap-info-table {
      .p-datatable-thead tr th {
        font-weight: 600;
        font-size: 1.13rem;
      }

      .p-datatable-tbody tr td {
        background: $gray-light !important; /* stylelint-disable-line declaration-no-important */
        height: 60px !important; /* stylelint-disable-line declaration-no-important */
        font-size: 1.13rem;
        color: $body-color;
      }
    }
  }

  .cap-list-renderer-header-actions {
    button.cap-button:not(:disabled) {
      font-weight: 600;
      font-size: 1.25rem;
      width: 9rem;

      &:hover {
        .p-button-label {
          text-decoration: none;
        }
      }

      .p-button-label {
        text-align: end;
      }
    }
  }
}

.cap-calendar.cap-rounded.p-calendar input {
  border-color: $gray;
  border-radius: 20px !important; /* stylelint-disable-line declaration-no-important */
  font-weight: 600;
  height: 35px;
  min-width: 130px;
  padding-left: 39px;
}

div.filter-icon i {
  font-size: 0.81rem;
}

cap-sidebar {
  p-sidebar {
    .p-sidebar {
      width: 486px;
    }
  }

  .cap-sidebar-footer > cap-button button.cap-button.success {
    position: absolute;
    right: 20px;
  }
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: transparent;
  color: $dark-gray;
}

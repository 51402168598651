[styleclass='p-paginator-bottom'] .p-paginator.p-component {
  .p-paginator-element {
    &.p-paginator-prev,
    &.p-paginator-next {
      anglelefticon,
      anglerighticon {
        display: none;
      }
    }
  }
}

.p-paginator {
  .p-paginator-element {
    anglelefticon,
    anglerighticon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.cap-info-table.p-datatable .p-paginator .paginator-left__page-text {
  margin-left: 0;
}

@import 'abstracts';

cpb-form-renderer {
  cap-calendar .p-inputgroup input.p-inputtext[readonly],
  cap-calendar .p-inputgroup input.p-inputtext[readonly]:enabled:hover:not(.p-state-error),
  cap-calendar .p-inputgroup input.p-inputtext[readonly]:enabled:hover {
    border: 1px solid $border-color;
  }

  .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container {
    flex-direction: row-reverse;

    cpb-checkbox-input > div {
      justify-content: end;
    }

    .label-wrapper {
      flex: 0 1 51%;
      max-width: 51%;
      margin-left: 10px;

      label {
        font-weight: normal;
      }
    }
  }

  .cbp-default-input-wrapper {
    .cpb-required-indicator {
      color: var(--cap-primary-color);
      margin-left: 3px;
      padding-right: 5px;
    }

    .readonly-container {
      min-height: 30px;
      color: $body-color;
      font-size: 1.13rem;
    }

    &.cpb-readonly.form-group.position-before {
      margin: 10px 0;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }
      @include media-breakpoint-up(xxl) {
        flex-direction: row;
      }
    }

    &.form-group.position-before {
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      .label-wrapper {
        flex: 0 1 100%;
        max-width: 100%;
        @include media-breakpoint-up(sm) {
          flex: 0 1 40%;
          max-width: 40%;
        }
      }
    }

    label {
      color: $label-color;
      font-size: 1.13rem;
      font-weight: 600;
    }
  }

  cpb-label-input {
    label {
      margin: 0;
    }
  }

  formly-group .field-wrapper {
    font-size: 1.13rem;
    color: $body-color;

    .cap-textarea {
      font-size: 1.13rem;
    }
  }

  .border-columns > .border-column {
    padding: 0 30px;
  }

  .border-columns > .border-column:not(:last-child) {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
      border-right: 1px solid $border-color;
      margin-bottom: 0;
    }
  }

  .p-calendar.p-calendar-w-btn > .p-inputtext {
    align-items: center;
    color: $body-color;
    border-radius: 3px;
    font-size: 1.13rem;
    font-weight: normal;
    height: 60px;

    &,
    &:enabled:focus:not(.p-state-error),
    &.p-inputtext:not(.p-disabled):hover,
    &.p-inputtext:not(.p-disabled).p-focus {
      height: 60px;
    }
  }

  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 60px;
    width: 60px;
    font-size: 1.13rem;
  }

  cap-timepicker {
    .content {
      display: flex;
      align-items: center;
      font-size: 1.13rem;
      padding: 16px !important; /* stylelint-disable-line declaration-no-important */

      .p-inputtext {
        margin: 0 45px;
      }
    }
  }

  cpb-dropdown-input .cap-dropdown.p-dropdown,
  cpb-dropdown-input .cap-dropdown.p-dropdown:hover {
    .p-dropdown-clear-icon {
      height: 22px !important; /* stylelint-disable-line declaration-no-important */
      width: 22px !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

body .p-datepicker {
  width: 100%;
}

.cap-table-filters {
  > .row {
    align-items: center;
  }

  .filters-label {
    flex-grow: 0;
    color: $gray-dark-secondary;

    @include media-breakpoint-up(lg) {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 0.81rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 0.94rem;
    }
  }

  .filters {
    display: flex;

    cap-dynamic-filters {
      display: flex;

      .cap-button {
        .p-button-label {
          @include media-breakpoint-up(lg) {
            font-size: 0.88rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 0.94rem;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 1rem;
          }
        }
      }

      .cap-dynamic-filter-layout .filter-icon {
        opacity: 0.5;
      }
    }
  }
}

.cap-info-table {
  .row-action {
    .fas {
      color: var(--cap-primary-color);
    }
  }

  .cap-info-table__header {
    font-weight: 600;

    @include media-breakpoint-up(lg) {
      font-size: 0.88rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 0.93rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1rem;
    }
  }
}

@import 'abstracts';

.p-dialog.p-confirm-dialog {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    border: none;
    border-radius: inherit;
    padding: 20px 30px 27px;

    p-footer {
      @include flexCentering();

      /* Yes/No buttons tend to be kind of small */
      .cap-button {
        min-width: 6em;
      }
    }
  }

  .p-dialog-header {
    @include straightBottom();
    font-family: $font-semi-bold;
    font-size: 1.63rem;

    .p-dialog-title {
      font-weight: 600;
      width: 100%;
    }

    .p-dialog-header-icons {
      .p-dialog-header-icon {
        color: $white !important; /* stylelint-disable-line declaration-no-important */
        text-align: center;
      }
    }
  }

  .p-dialog-content {
    .p-confirm-dialog-message {
      text-align: center;
      font-size: 1.5rem;
      width: 100%;
      color: var(--primary-text);
    }
  }
}

div.p-overlaypanel {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
  border: none;

  .p-overlaypanel-content {
    padding: 0;

    ul {
      padding: 0;
    }
  }
}

@import 'abstracts';

cpb-form-renderer {
  .cap-dropdown.p-dropdown,
  .cap-multi-select.p-multiselect {
    align-items: center;
    color: $body-color;
    border-radius: 3px;
    font-size: 1.13rem;
    font-weight: normal;

    &,
    &.p-dropdown:not(.p-disabled):hover,
    &.p-dropdown:not(.p-disabled).p-focus,
    &.p-multiselect:not(.p-disabled):hover,
    &.p-multiselect:not(.p-disabled).p-focus {
      height: 60px;

      .p-dropdown-label,
      .p-multiselect-label {
        font-size: 1.13rem;
      }

      .p-dropdown-trigger,
      .p-multiselect-trigger {
        padding-right: 20px;
        color: $gray;
      }

      .p-dropdown-clear-icon,
      .p-multiselect-clear-icon {
        color: $gray;
        height: 16px;
        width: 16px;
      }
    }
  }
}

.p-dropdown-empty-message,
.p-multiselect-empty-message {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

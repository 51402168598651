@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

// Override Bootstrap
// Note: since v5
// Renamed .ml-* and .mr-* to .ms-* and .me-*.
// Renamed .pl-* and .pr-* to .ps-* and .pe-*.
// Renamed .text-left and .text-right to .text-start and .text-end.

.float-right {
  float: right !important; /* stylelint-disable-line declaration-no-important */
}

.text-left {
  text-align: left !important; /* stylelint-disable-line declaration-no-important */
}

.text-right {
  text-align: right !important; /* stylelint-disable-line declaration-no-important */
}

.form-group {
  margin-bottom: 1rem;
}

.row {
  > cap-button {
    width: auto;
  }

  [class*='col-'] {
    position: relative;
  }
}
